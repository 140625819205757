


























































import { Vue, Prop, Component } from 'vue-property-decorator';
import fromUnixTime from 'date-fns/fromUnixTime';
import Qs from 'qs';
import {Charge} from "@/repositories/company/payment-repository";

@Component({
  filters: {
    fromUnixTime
  }
})
export default class extends Vue {
  private charge:Charge|null = null;

  private created() {
    this.charge = new Charge(Qs.parse(location.search.substring(1)) as any);
  }

  private mounted() {
    window.print();
  }
}
